import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/style/global.styl";

import * as filters from "./filters";
import mixins from "./mixins/mixins";

Vue.config.productionTip = false;

Vue.mixin(mixins);

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
