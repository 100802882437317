export default {
  data() {
    return {
      clientHeight: "",
    };
  },
  mounted() {
    if (document.querySelector(".h-search")) {
      this.clientHeight =
        document.documentElement.clientHeight -
        document.querySelector(".h-search").clientHeight -
        260;
    } else {
      this.clientHeight = document.documentElement.clientHeight - 290;
    }
  },
};
