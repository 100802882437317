import dayjs from "dayjs";

export const formatCellphone = (val) => {
  return val.substring(0, 3) + "****" + val.substring(7, 12);
};

export const formatNickname = (val) => {
  return val.substring(0, 2) + "**";
};

export const formatName = (val) => {
  return (
    val
      .replace("彩票", "")
      .replace("店铺", "")
      .replace("店", "")
      .replace("竞彩", "")
      .replace("体彩", "")
      .replace("彩", "")
      .replace("竞猜", "")
      .replace("乐投", "")
      .replace("店主", "")
      .replace("体育", "")
      .replace("赢", "")
      .replace("收", "")
      .replace("单", "")
      .replace("博", "")
      .substring(0, 2) + "**"
  );
};

export const formatGender = (val) => {
  let dict = {
    0: "未设置",
    1: "男",
    2: "女",
  };
  return dict[val];
};

export const formatState = (val) => {
  let dict = {
    1: "已启用",
    0: "未激活",
    "-1": "已禁用",
  };
  return dict[val];
};

export const formatDateTime = (val) => {
  if (val) {
    return dayjs(val).format("YYYY-MM-DD HH:mm:ss");
  } else {
    return "--";
  }
};

export const formatTimestamp = (val) => {
  if (val) {
    return dayjs(Number(val)).format("YYYY-MM-DD HH:mm:ss");
  } else {
    return "--";
  }
};

export const formatDate = (val) => {
  if (val) {
    return dayjs(val).format("YYYY-MM-DD");
  } else {
    return "--";
  }
};

export const formatIsSuper = (val) => {
  let dict = {
    1: "是",
    "-1": "否",
  };
  return dict[val];
};

export const formatNullValue = (val) => {
  if (val) {
    return val;
  } else {
    return "--";
  }
};

export const formatStoreState = (val) => {
  let dict = {
    1: "已开启",
    "-1": "已关闭",
  };
  return dict[val];
};

export const formatGroupState = (val) => {
  let dict = {
    1: "使用中",
    "-1": "已封禁",
  };
  return dict[val];
};

export const formatGroupMemberRole = (val) => {
  let dict = {
    3: "群主",
    2: "管理员",
    1: "群成员",
  };
  return dict[val];
};

export const formatConversationType = (val) => {
  let dict = {
    PERSON: "私聊",
    GROUP: "群聊",
  };
  return dict[val];
};

export const formatComplaintState = (val) => {
  let dict = {
    "-1": "已忽略",
    0: "待处理",
    1: "已处理",
  };
  return dict[val];
};

export const formatComplaintType = (val) => {
  let dict = {
    1: "骚扰",
    2: "欺骗",
    3: "盗号",
    4: "黄赌毒",
  };
  return dict[val];
};

export const formatComplaintConversationType = (val) => {
  let dict = {
    1: "私聊",
    2: "群聊",
  };
  return dict[val];
};

export const formatApplicationState = (val) => {
  let dict = {
    1: "已上架",
    "-1": "已下架",
  };
  return dict[val];
};

export const formatUpdateType = (val) => {
  let dict = {
    1: "一般更新",
    2: "强制更新",
  };
  return dict[val];
};

export const formatAppType = (val) => {
  let dict = {
    1: "Android",
    2: "iOS",
  };
  return dict[val];
};

export const formatURLState = (val) => {
  if (val) {
    return "已上传";
  } else {
    return "未上传";
  }
};

export const formatInviteCodeState = (val) => {
  let dict = {
    "-1": "已禁用",
    1: "已启用",
  };
  return dict[val];
};

export const formatTenantState = (val) => {
  let dict = {
    "-1": "已拒绝",
    0: "待审核",
    1: "已通过",
  };
  return dict[val];
};

export const formatTenantUserRole = (val) => {
  let dict = {
    1: "客户",
    2: "代理",
    3: "成员",
  };
  return dict[val];
};

export const formatTenantApplicationRoleScope = (val) => {
  let dict = {
    0: "全员可见",
    1: "仅客户可见",
    2: "仅代理可见",
    3: "仅成员可见",
  };
  return dict[val];
};

export const formatIsAddInviter = (val) => {
  if (val === 1) {
    return "是";
  } else {
    return "否";
  }
};
