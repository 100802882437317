import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "login" */ "@/views/Login"),
  },
  {
    path: "/",
    name: "layout",
    redirect: "login",
    component: () => import(/* webpackChunkName: "layout" */ "@/views/Layout"),
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import(/* webpackChunkName: "home" */ "@/views/Home"),
      },
      {
        path: "/tenant",
        name: "tenant",
        component: () =>
          import(/* webpackChunkName: "tenant" */ "@/views/tenant/Index"),
      },
      {
        path: "/tenant/:pk",
        name: "tenant-detail",
        component: () =>
          import(
            /* webpackChunkName: "tenant-detail" */ "@/views/tenant/Detail"
          ),
      },
      {
        path: "/user",
        name: "user",
        component: () => import(/* webpackChunkName: "user" */ "@/views/User"),
      },
      {
        path: "/group",
        name: "group",
        component: () =>
          import(/* webpackChunkName: "group" */ "@/views/Group"),
      },
      {
        path: "/invite",
        name: "invite",
        component: () =>
          import(/* webpackChunkName: "invite" */ "@/views/Invite"),
      },
      {
        path: "/game",
        name: "game",
        component: () => import(/* webpackChunkName: "game" */ "@/views/Game"),
      },
      {
        path: "/content",
        name: "content",
        component: () =>
          import(/* webpackChunkName: "content" */ "@/views/Content"),
      },
      {
        path: "/complaint",
        name: "complaint",
        component: () =>
          import(/* webpackChunkName: "complaint" */ "@/views/Complaint"),
      },
      {
        path: "/feedback",
        name: "feedback",
        component: () =>
          import(/* webpackChunkName: "feedback" */ "@/views/Feedback"),
      },
      {
        path: "/application",
        name: "application",
        component: () =>
          import(/* webpackChunkName: "application" */ "@/views/Application"),
      },
      {
        path: "/admin",
        name: "admin",
        component: () =>
          import(/* webpackChunkName: "admin" */ "@/views/Admin"),
      },
      {
        path: "/version",
        name: "version",
        component: () =>
          import(/* webpackChunkName: "version" */ "@/views/Version"),
      },
      {
        path: "/setting",
        name: "setting",
        component: () =>
          import(/* webpackChunkName: "setting" */ "@/views/Setting"),
      },
      {
        path: "/record/login",
        name: "record-login",
        component: () =>
          import(/* webpackChunkName: "record-login" */ "@/views/record/Login"),
      },
      {
        path: "/record/handle",
        name: "record-handle",
        component: () =>
          import(
            /* webpackChunkName: "record-handle" */ "@/views/record/Handle"
          ),
      },
      {
        path: "/record/api",
        name: "record-api",
        component: () =>
          import(/* webpackChunkName: "record-api" */ "@/views/record/Api"),
      },
      {
        path: "/logou",
        name: "logout",
        component: () =>
          import(/* webpackChunkName: "logout" */ "@/views/Logout"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
