import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    objInformation: {
      username: "",
      is_super: -1,
    },
  },
  mutations: {
    handleInitInformation(state, data) {
      state.objInformation = data;
    },
    handleClear(state) {
      state.objInformation = {};
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
});
